import React from 'react';
import styled from 'styled-components';
import ConditionalRenderer from './conditionalRenderer';
import { renderButtons } from '../helpers/matches';

const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  min-width: ${({ theme }) => theme.dimensions.mobile.min};
  min-height: 150px;
  border-radius: 5px;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto auto;
  grid-template-areas: 'championship' 'score' 'teams' 'info';
  justify-items: center;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.text.calcGenerically(8, 32)} 0;
`;

const Date = styled.div`
  background: ${({ theme, home }) =>
    home ? theme.colors.primary_dark : theme.colors.secondary};
  color: ${({ theme, home }) =>
    home ? theme.colors.text_on_primary : theme.colors.secondary_light};
  min-width: 60px;
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.2rem;
  border-radius: 5px 0 0 5px;
`;

const Day = styled.span`
  font-size: ${({ theme }) => theme.text.calcGenerically(24, 48)};
  font-weight: bold;
`;

const Month = styled.span`
  font-size: ${({ theme }) => theme.text.calcGenerically(12, 32)};
  font-weight: bold;
  text-transform: uppercase;
`;

const Weekday = styled.span`
  font-size: ${({ theme }) => theme.text.calcGenerically(10, 24)};
  text-transform: uppercase;
`;

const Championship = styled.span`
  font-weight: bold;
  font-size: ${({ theme }) => theme.text.calcGenerically(12, 24)};
  margin-bottom: 0.5rem;
  text-align: start;
  grid-area: championship;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  width: 100%;
  padding: 0.5rem 0.5rem;
  grid-area: info;
`;

const Stadium = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.text.calcGenerically(12, 24)};
  text-align: end;
`;

const Time = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ theme }) => theme.text.calcGenerically(12, 32)};
  font-weight: bold;
`;

const Score = styled.div`
  display: flex;
  min-width: 140px;
  width: 100%;
  /* padding: 0.5rem 0.2rem; */
  justify-content: space-between;
  align-items: center;
  grid-area: score;
  height: 100%;
`;

const Goals = styled.span`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.text.calcGenerically(32, 64)};
  font-weight: bold;
`;

const Versus = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

const Logo = styled.figure`
  width: 25%;
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    max-width: 88px;
    min-width: 48px;
  }

  figcaption {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Teams = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    width: 25%;
    font-size: ${({ theme }) => theme.text.calcGenerically(10, 24)};
    font-weight: bold;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
`;

export default function MatchCard(props) {
  const { match } = props;

  return (
    <Container>
      <Date home={match.houseGame}>
        <ConditionalRenderer
          condition={
            !(match.timeText === '00:00' || /^a\s+definir/i.test(match.time))
          }
          renderOnSuccess={() => (
            <>
              <Day>{match.day}</Day>
              <Month>{match.month}</Month>
              <Weekday>{match.weekName}</Weekday>
            </>
          )}
        />
      </Date>
      <Inner>
        <Championship>{match.championship}</Championship>
        <Score>
          <Logo>
            <img src={match.homeImg} alt={match.homeTeamName} />
          </Logo>
          <Goals>{match.homeGols}</Goals>
          <Versus>X</Versus>
          <Goals>{match.visitorGols}</Goals>
          <Logo>
            <img src={match.visitorImg} alt={match.visitorTeamName} />
          </Logo>
        </Score>
        <Teams>
          <span>{match.homeTeamName}</span>
          <span>{match.visitorTeamName}</span>
        </Teams>
        <Info>
          <Time>{match.time}</Time>
          <Stadium>{match.stadium}</Stadium>
        </Info>
        <Buttons>{renderButtons(match)}</Buttons>
      </Inner>
    </Container>
  );
}
