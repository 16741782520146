import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import MatchCard from '../components/match-card';
import ConditionalRenderer from '../components/conditionalRenderer';
import Select from '../components/select';
import Container from '../components/container';
import FlexDiv from '../components/flex-div';
import {
  getCalendar,
  parseCalendar,
  getChampionships,
  filterByChampionshipId,
} from '../helpers/matches';
import NextMatchWidget from '../components/next-match-widget';
import Loader from '../components/loader';

const Calendar = styled.div`
  text-align: center;
  h1 {
    text-transform: uppercase;
    margin: 1rem;
  }
  h2 {
    text-transform: uppercase;
    font-size: 2rem;
    margin: 1rem;
  }
`;

const List = styled.ol`
  list-style: none;
  margin: 0;
  margin-top: 2rem;
  width: 100%;
`;

const Item = styled.li`
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 0;
  max-width: ${({ theme }) => theme.dimensions.desktop.content};
`;

const NextMatchSection = styled.section`
  margin-bottom: 0;
`;

function ListMatches({ matches }) {
  return (
    <ConditionalRenderer
      condition={matches && matches.length > 0}
      renderOnSuccess={() => (
        <List>
          {matches.map((match) => (
            <Item key={match.gameId}>
              <MatchCard match={match} />
            </Item>
          ))}
        </List>
      )}
    />
  );
}

const initialState = {
  matches: [],
  previous: [],
  next: null,
  following: [],
};

export default function Games(props) {
  const {
    pageContext: { pages },
    data: {
      site: {
        siteMetadata: { groundAPI },
      },
      wpgraphql: {
        matches: { nodes: featuredMatches },
      },
    },
  } = props;

  const [activeChampionship, setActiveChampionship] = useState(null);
  const [championships, setChampionships] = useState([]);
  const [calendar, setCalendar] = useState(initialState);
  const [visibleMatches, setVisibleMatches] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getCalendar(groundAPI, featuredMatches);
      const parsed = parseCalendar(data);
      setCalendar({ ...parsed, matches: data });
      setChampionships(getChampionships(data));
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!activeChampionship) return setVisibleMatches({ ...calendar });
    const { next } = calendar;

    setVisibleMatches({
      ...calendar,
      next: next.championshipId === activeChampionship ? next : null,
      previous: filterByChampionshipId(calendar.previous, activeChampionship),
      following: filterByChampionshipId(calendar.following, activeChampionship),
    });
  }, [activeChampionship, calendar]);

  return (
    <Calendar>
      <ConditionalRenderer
        condition={loading}
        renderOnFailure={() => (
          <>
            <ConditionalRenderer
              condition={championships.length}
              renderOnSuccess={() => (
                <Container>
                  <FlexDiv marginTop="2rem" justifyContent="flex-end">
                    <Select
                      options={championships}
                      placeholder="Escolha o campeonato"
                      active={championships.find(
                        (item) => item.value === activeChampionship,
                      )}
                      onChange={setActiveChampionship}
                    />
                  </FlexDiv>
                </Container>
              )}
            />
            <ListMatches matches={visibleMatches.previous} />
            <ConditionalRenderer
              condition={visibleMatches.next}
              renderOnSuccess={() => (
                <NextMatchSection>
                  <NextMatchWidget
                    match={visibleMatches.next}
                    hideMore
                    scrollTo
                  />
                </NextMatchSection>
              )}
            />
            <ListMatches matches={visibleMatches.following} />
          </>
        )}
        renderOnSuccess={() => (
          <Loader size="90" color="primary" style={{ margin: '3rem 0' }} />
        )}
      />
    </Calendar>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        groundAPI
      }
    }
    wpgraphql {
      matches(first: 100) {
        nodes {
          servicoDeJogo {
            checkin {
              enabled
              startDate
              startTime
              endDate
              endTime
              link
            }
            tickets {
              enabled
              startDate
              startTime
              endDate
              endTime
              link
            }
            matchId
            showButton
            matchPageLink
            review {
              ... on WPGraphQL_Post {
                id
                slug
              }
            }
          }
          slug
        }
      }
    }
  }
`;
